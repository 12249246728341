import React from "react";
// import LandingNavbar from "../components/sidebar/LandingNavbar";
import { Navigate, useLocation } from "react-router-dom";
import AdminSideBar from "../component/AdminSidebar";
import SubAdminsidebar from "../component/SubAdminsidebar";

const PublicRoute = ({ ...rest }) => {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const location = useLocation();

  if (!token) {
    return <>{rest.element}</>;
  } else if (token && userRole === "super") {
    return <Navigate to="/" />;
  } else if (token && userRole === "basic") {
    return <Navigate to="/admin" />;
  }
};
export default PublicRoute;



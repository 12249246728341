// EditModal.js
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { patchFormData } from "../Services/AxoisInterceptorr";
import { toast } from "react-toastify";
import VisitorEndpoint from "../Services/VisitorEndpoint";
import { useGetStatus } from "./customHooks/customHook";

const EditModal = ({ open, onClose, row, onFormSubmit }) => {
  const [formData, setFormData] = useState({
    status: row ? row.status : "",
    notes: "",
  });

  const allstatus = useGetStatus(open);
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = () => {
    const otpData = {
      visitorId: row,
      statusName: formData.status,
      adminNote: formData.notes,
    };
    patchFormData(
      VisitorEndpoint.STATUS_UPDATE,
      otpData,
      () => {},
      (res) => {
        if (res) {
          if (res && res.status === 200) {
            toast.success(res.data.message);

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    // onFormSubmit(formData);
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          background: "#FFFFFF",
          padding: "50px",
          borderRadius: "20px",
        }}
      >
        <h2 className="edit_heading">Edit Details</h2>
        <TextField
          select
          label="Status"
          name="status"
          value={formData.status}
          onChange={handleFormChange}
          fullWidth
          sx={{ fontFamily: '"Quicksand", sans-serif' }}
        >
          {allstatus.map((stauts) => (
            <MenuItem
              key={stauts.status}
              value={stauts.status}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              {stauts.status}
            </MenuItem>
          ))}
        </TextField>
        <div className="edit_gap">
          <TextField
            required
            label="Notes"
            name="notes"
            value={formData.notes}
            onChange={handleFormChange}
            multiline
            rows={4}
            fullWidth
          />
        </div>
        <Button
          onClick={handleFormSubmit}
          sx={{
            marginTop: 2,
            background: "#901941",
            color: "#FFFFFF",
            fontFamily: '"Quicksand", sans-serif',
            borderRadius: "20px",
            padding: "5px 10px",
            border: "2px solid #901941",

            "&:hover": {
              backgroundColor: "#ffffff",
              color: "#901941",
              border: "2px solid #901941",
            },
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default EditModal;

import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CustomReadOnlyTextField from "./ReadonlyInput";
import { useDetailsId } from "./customHooks/customHook";

const UserDetailsModal = ({ open, onClose, userData }) => {
  const detailsId = useDetailsId(userData, open);

  
  const selectSpaceData =
    detailsId && detailsId?.selectSpace.map((column) => column).join(", ");

  if (!detailsId) {
    return null;
  }

  const {
    image,
    name,
    email,
    number,
    status,
    personToMeet,
    numberOfVisitors,
    purpose,
    selectedSpaces,
    numberOfSeats,
    startDate,
  } = userData;

  // Custom class for strong elements
  const strongClass = "user-details-strong";
  const customTypographyClass = {
    color: "#000000",
    fontFamily: '"Quicksand", sans-serif',
    fontSize: "16px",
    fontWeight: 500,
  };
  
 
  const getStatusBackgroundColor = () => {
    switch (detailsId?.status.status) {
      case "Completed":
        return "#228B22";
      case "Pending":
        return "#ff0000";
      case "In Review":
        return "#87CEEB";
      default:
        return "#FFFFFF";
    }
  };
  let dateStr =  detailsId?.startDate;
  const splitDate = dateStr ? dateStr.split("T") : [];
  

  const statusBackgroundColor = getStatusBackgroundColor();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal_user">
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} align="center">
                <Avatar
                  src={detailsId?.visitorImage}
                  alt={name}
                  sx={{ width: 120, height: 120 }}
                />
                <div>
                  <Typography
                    sx={{
                      fontFamily: '"Quicksand", sans-serif',
                      color: "#901941",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                    variant="h5"
                    component="div"
                    gutterBottom
                  >
                    {detailsId?.name}
                  </Typography>
                  <Typography
                    sx={{
                      ...customTypographyClass,
                      background: statusBackgroundColor,
                      borderRadius: "20px",
                      color: "#FFFFFF",
                      padding: "5px 0px",
                      margin: "10px 0px",
                    }}
                    variant="body1"
                  >
                    {detailsId?.status.status}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field1"
                      label="Email:"
                      value={detailsId?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field2"
                      label="Phone:"
                      value={detailsId?.mobileNumber}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "none" }} />{" "}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field3"
                      label="Person to Meet:"
                      value={detailsId?.personToMeet}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field4"
                      label="Company Name:"
                      value={detailsId?.companyName}
                    />
                  </Grid>
                </Grid>
             
                <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field4"
                      label="Purpose:"
                      value={detailsId?.purposeOfVisit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field4"
                      label="No. of Visitors:"
                      value={detailsId?.numberOfVisitors}
                    />
                  </Grid>
                
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                <Grid container spacing={2}>
                {selectSpaceData && (
                  <Grid item xs={12} sm={6}>
                    <CustomReadOnlyTextField
                      id="field3"
                      label="Selected Spaces:"
                      value={selectSpaceData}
                    />
                  </Grid>
                  )}
                  {detailsId?.numberOfSeats && (
                    <Grid item xs={12} sm={6}>
                      <CustomReadOnlyTextField
                        id="field4"
                        label="Number of Seats:"
                        value={detailsId?.numberOfSeats}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                <Grid container spacing={2}>
                
                { detailsId?.startDate && selectSpaceData &&(
                    <Grid item xs={12} sm={6}>
                      <CustomReadOnlyTextField
                        id="field"
                        label="Start Date:"
                        value={
                          detailsId?.startDate &&
                          splitDate[0]
                        }
                      />
                    </Grid>
                  )}

                  {detailsId?.otherNote && (
                    <Grid item xs={12} sm={6}>
                      <CustomReadOnlyTextField
                        id="field8"
                        label="Other:"
                        multiline
                        rows={1}
                        value={detailsId?.otherNote}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <CustomReadOnlyTextField
                      id="field8"
                      label="Note:"
                      multiline
                      rows={4}
                      value={detailsId?.adminNote}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{
                textAlign: "right",
                fontFamily: '"Quicksand", sans-serif',
                color: "#901941",
                fontSize: "16px",
                fontWeight: "600",
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;

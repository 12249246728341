import Icon from "../assets/images/camera1.png"
import PeopleIcon from '@mui/icons-material/People';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const menuItem = [
    {
      path: "/",
      name: "Visitors Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/admin",
      name: "Sub Admin",
      icon: <ManageAccountsIcon sx={{fontSize:"30px"}} />,
    },


 
  ];
  
export const menuSubItem = [
    {
      path: "/",
      name: "Visitors Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
  


 
  ];
  
import React, { useEffect, useState } from "react";
import HeaderComponent from "../component/HeaderComponent";
import StickyHeadTable from "../component/styleTabel";



const VisitorsDetails = () => {

 

  return (
    <>
      <div className="container_fluid">
        <HeaderComponent />
        <div className="container">
      <StickyHeadTable/>
        </div>
      </div>
    </>
  );
};

export default VisitorsDetails;

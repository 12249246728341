export const BASE_URL = process.env.REACT_APP_BASE_URL;

const VisitorEndpoint = {
  LOGIN_ADMIN: "/api/v1/admin/login",
  CREATE_ADMIN: "/api/v1/admin/register",
  GET_VISITORS_DETAILS: "/api/v1/visitor/all",
  GET_VISITORS_DETAILS_BY_ID: "/api/v1/visitor/single",
  DELETE_VISITORS: "/api/v1/visitor/delete",
  STATUS_UPDATE: "/api/v1/visitor/statusupdate",
  ALL_STATUS: "/api/v1/status/all",
  CREATE_SUBADMIN: "/api/v1/admin/register",
  DELETE_SUBADMIN: "/api/v1/admin/delete",
  GET_SUBADMIN_DATA: "/api/v1/admin/all",
  

 

};

export default VisitorEndpoint;

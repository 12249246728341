import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import AdminSideBar from "../component/AdminSidebar";
import SubAdminsidebar from "../component/SubAdminsidebar";

const PrivateRoute = ({ ...rest }) => {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");

  if (token && userRole === 'super') {
    return (
      <>
        <AdminSideBar>
          {/* <NavBar /> */}
          {rest.element}
        </AdminSideBar>
      </>
    );
  } else if (token && userRole === 'basic') {
    return (
      <>
        <SubAdminsidebar>
          {rest.element}
        </SubAdminsidebar>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;

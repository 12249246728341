import { TextField } from '@mui/material';
import React from 'react';

const CustomTextField = ({ label,type, name, value, onChange, ...props }) => (
  <TextField
    label={label}
    type={type}
    name={name}
    required
    InputProps={{
      style: {
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: '"Quicksand", sans-serif',
        color: "#000000",
      }
    }}
    InputLabelProps={{
      style: {
        fontWeight: 600,
        fontSize: "18px",
        fontFamily: '"Quicksand", sans-serif',
        color: "#901941",
      }
    }}
    value={value}
    onChange={onChange}
    fullWidth
    margin="normal"
    {...props}
  />
);

export default CustomTextField;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import { postJsonData } from "../Services/AxoisInterceptorr";
import VisitorEndpoint from "../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import CustomTextField from "./textfield/CoustomTextfield";

const AddMemberPopup = ({ isOpen, onClose }) => {
  const [newMemberData, setNewMemberData] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "email":
        error = validateEmail(value);
        break;
      case "password":
        error = validatePassword(value);
        break;
      case "number":
        error = validatePhoneNumber(value);
        break;
      default:
        break;
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const validatePhoneNumber = (phoneNumber) => {
    const isValidLength = phoneNumber.length === 10;
    const isValidFormat = /^[0-9]+$/.test(phoneNumber);

    if (!isValidLength) {
      return "Phone number must be 10 digits long.";
    }

    if (!isValidFormat) {
      return "Phone number can only contain digits (0-9).";
    }

    return "";
  };
  const validateEmail = (email) => {
    const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    return regex.test(email) ? "" : "Please enter valid email address";
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password)
      ? ""
      : "*Please must be at least 8 characters one digit one special character one uppercase.";
  };

  const handleAddMember = (e) => {
    e.preventDefault();

    const isValid = Object.values(newMemberData).every((value, index) => {
      const name = Object.keys(newMemberData)[index];
      const error = validateInput(name, value);
      return error === "";
    });

    if (!isValid) {
      const jsonData = {
        email: newMemberData.email,
        password: newMemberData.password,
        name: newMemberData.name,
        mobileNumber: newMemberData.number,
        role: newMemberData.role,
      };

      postJsonData(
        VisitorEndpoint.CREATE_SUBADMIN,
        jsonData,
        () => {},
        (res) => {
          if (res && res.status === 201) {
            toast.success(res.data.message);
          }
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );

      setNewMemberData({
        name: "",
        number: "",
        email: "",
        password: "",
      });
      onClose();
    } else {
      // Form is not valid, handle accordingly (show errors, etc.)
      toast.error("Form contains validation errors. Please check.");
    }
  };
  const customTypographyStyles = {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: '"Quicksand", sans-serif',
    color: "red",
    marginBottom: "10px",
  };
  const allrole = [
    {
      status: "super",
    },
    {
      status: "basic",
    },
  ];
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center" }}>Add Member</DialogTitle>
      <form onSubmit={handleAddMember}>
        <DialogContent>
          <TextField
            label="Name"
            type="text"
            name="name"
            required
            value={newMemberData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          {validationErrors.name && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.name}
            </Typography>
          )}

          <TextField
            label="Phone"
            type="number"
            name="number"
            required
            value={newMemberData.number}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          {validationErrors.number && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.number}
            </Typography>
          )}

          <TextField
            label="Email"
            type="text"
            name="email"
            value={newMemberData.email}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          {validationErrors.email && (
            <Typography sx={customTypographyStyles}>
              {validationErrors.email}
            </Typography>
          )}

          <TextField
            label="Password"
            type="text"
            name="password"
            value={newMemberData.password}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          {validationErrors.password && (
            <Typography sx={customTypographyStyles}>
            {validationErrors.password}
            </Typography>
          )}

          <CustomTextField
            select
            label="Role"
            name="role"
            value={newMemberData.status}
            onChange={handleInputChange}
       
          >
            {allrole.map((stauts) => (
              <MenuItem
                key={stauts.status}
                value={stauts.status}
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: "Quicksand, sans-serif",
                }}
              >
                {stauts.status}
              </MenuItem>
            ))}
          </CustomTextField>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#901941" }}
            type="submit"
            variant="contained"
          >
            Add Member
          </Button>
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddMemberPopup;

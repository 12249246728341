import React from "react";
import VisitorsDetails from "../pages/VisitorsDetails";
import LoginForm from "../pages/LoginAdmin";
import SubAdminComponent from "../pages/SubAdmin";


//Common
// const PageNotFound = React.lazy(() => import("../error/PageNotFound"));
// const ForbiddenAccess = React.lazy(() => import("../error/ForbiddenAccess"));

// login-SignUp
// const Login = React.lazy(() => import("../pages/user/login"));
// const NewUserForm = React.lazy(() => import("../pages/user/NewUserForm"));
// const RecoverAccount = React.lazy(() => import("../pages/user/RecoverAccount"));

// public-Pages

// private pages
// const Opportunities = React.lazy(() => import("../pages/Opportunities/index"));



const configureRoutes = () => {
  const routes = [
    {
      element: <VisitorsDetails />,
      exact: true,
      path: "/",
      title: "Home page",
      type: "private",
    },
    {
      element: <LoginForm />,
      exact: true,
      path: "/login",
      title: "Home page",
      type: "public",
    },
    {
      element: <SubAdminComponent />,
      exact: true,
      path: "/admin",
      title: "sub admin",
      type: "private",
    },


    // {
    //   element: <PageNotFound />,
    //   exact: true,
    //   path: "*",
    //   title: "404 Not Found",
    //   type: "error",
    // },
  ];

  return routes;
};

export default configureRoutes;

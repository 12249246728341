import React, { useEffect, useState } from "react";
import HeaderComponent from "../component/HeaderComponent";
import StickyHeadTableadmin from "../component/subadminTabel";



const SubAdminComponent = () => {

 

  return (
    <>
      <div className="container_fluid">
        <HeaderComponent />
        <div className="container">
      <StickyHeadTableadmin/>
        </div>
      </div>
    </>
  );
};

export default SubAdminComponent;

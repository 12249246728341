import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "../assets/images/letsbrimsmall.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import LogoutIcon from "@mui/icons-material/Logout";

import { menuSubItem } from "./Constant";

const SubAdminsidebar = ({ children }) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLogoutHover, setIsLogoutHover] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const logOut = () => {
    localStorage.clear();
  };

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedItem(currentPage);
  }, [currentPage]);

  return (
    <div className="sidebar_container">
      <div
        style={{
          width: "97px",
          borderRadius:"0px 20px 20px 0px",
          height: "100vh",
          position: "fixed",
        }}
        className="sidebar_contant"
      >
        <div className="top_section">
              <img
                src={Icon}
                width="40px"
                height="40px"
                alt="logo"
                id="closeButton"
              />
              {/* <MenuOpenIcon sx={{fontSize:"30px",color:"#901941"}} onClick={handleDrawerClose} /> */}
      
        </div>
        {menuSubItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            onClick={() => setSelectedItem(item.path)}
          >
            <div className="icon">
              <div className="icon-wrapper">
                  <div
                    style={{
                      color: selectedItem === item.path ? "#901941" : "#000000",
                    }}
                  >
                    {item.icon}
                  </div>
              </div>
            </div>
            <div
              style={{
                color: selectedItem === item.path ? "#901941" : "#000000",
              }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}

        <NavLink
          to="/login"
          className="link"
          activeClassName="active"
          onClick={() => {
            logOut();
          }}
        >
          <div className="icon">
            <LogoutIcon
              sx={{
                fontSize: "30px",
                color: selectedItem === "/login" ? "#901941" : "#000000",
              }}
            />
          </div>
          <div
            style={{
              // Change the text color for selected item
              color: selectedItem === "/login" ? "#901941" : "#000000",
            }}
            className="link_text"
          >
            Logout
          </div>
        </NavLink>
      </div>
      <main
      className="cover_conatiner"
      >
        {children}
      </main>
    </div>
  );
};

export default SubAdminsidebar;

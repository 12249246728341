import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { getAxios } from "../../Services/AxoisInterceptorr";



  

export function useVisitorDetails() {
  const [visitordetails, setVisitordetails] = useState([]);
  let fetchedData = false;
  const fetchVisitordetails = () => {

    const endpoint = VisitorEndpoint.GET_VISITORS_DETAILS;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        console.log("loki",response)
        setVisitordetails(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    if (!fetchedData) {
      fetchVisitordetails();
      fetchedData = true;
    }
  }, []);


  return visitordetails;
}
export function useGetStatus(open) {
  const [allstatus, setAllstatus] = useState([]);
  const fetchuseGetStatus = () => {
    if (open) {
      const endpoint = VisitorEndpoint.ALL_STATUS;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setAllstatus(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchuseGetStatus();
  }, [open]); 

  return allstatus;
}

export function useGetAllAdmin() {
  const [alladmindata, setAlladmindata] = useState([]);
  const fetchuseGetAllAdmin = () => {

    const endpoint = VisitorEndpoint.GET_SUBADMIN_DATA;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setAlladmindata(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseGetAllAdmin();
  }, []);

  return alladmindata;
}

export function useDetailsId(id, open) {
  const [detailsId, setDetailsId] = useState();

  const fetchuseDetailsId = () => {
    if (open) {
      const endpoint = `${VisitorEndpoint.GET_VISITORS_DETAILS_BY_ID}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setDetailsId(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchuseDetailsId();
  }, [id, open]);

  return detailsId;
}
